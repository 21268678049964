import React from "react";
import './contactline.css';

export class ContactLine extends React.Component{
    render(){
        return (
                <div className="desktop contactLine">
                    <span className="openingHours">
                        <p className="title">Öffnungszeiten:</p>
                        <p>Montag-Freitag 10-17.30 Uhr</p>
                        <p>Samstags 10-14.30 Uhr</p>
                    </span>
                    <br />
                    <span className="desktop contactInfo">
                        <a href="mailto:info@kannreisen.de"><p>info@kannreisen.de</p></a>
                        <p><a href="tel:+49-221-16534999">Tel: +49 221 16534999</a> | <a href="tel:+49-221-16534999">Fax: +49 221 16534990</a></p>
                        <p className="mobilnummer"><a href="tel:+49-176-23820405">Mob: +49 176 23820405</a></p>
                    </span>
                </div>
        )
    }
}