import React from 'react';
import { TopBar } from './TopBar/TopBar';
import { NavBurger } from './NavBurger/NavBurger';
import './NavBar.css';
import { useRef } from 'react';
import * as FaIcons from 'react-icons/fa';
import './main.css';
import { onlyGallery } from '../Functions/ShowGallery';
import { onlyContact } from '../Functions/ShowContact';
import { onlyHome } from '../Functions/showHome';

export function NavBar() {

    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
        document.getElementById('nav-burger').style.backgroundColor='rgba(255, 255, 255, 0.9)';
    }

    return(
        <header className='mobile'>
            <TopBar />

            <nav ref={navRef}>
                <span onClick={function(event){ onlyHome(); showNavbar();}}>Home</span>
                <span onClick={function(event){ onlyGallery(); showNavbar();}}>Gallerie</span>
                <span onClick={function(event){ onlyContact(); showNavbar();}}>Kontakt</span>
                <button className='nav-btn nav-close-btn' onClick={showNavbar}>
                    <FaIcons.FaTimes />
                </button>
            </nav>
            <span className='nav-btn' onClick={showNavbar}>
                <NavBurger className='nav-btn' onClick={showNavbar}/>
            </span>
        </header>
    );
    
}
