import React from "react";
import './dividerb.css';

export class DividerB extends React.Component{
    render(){
        return (
        <div className="divider-sectionB" id="dividerB">
            <div className="upperBarB"></div>
            <div className="arrowB"></div>
            <div className="lowerBarB"></div>
        </div>)
    }
}