import React from "react";
import { Desktop } from "./Desktop/Desktop";
// import { Tablet } from "./Tablet/Tablet";
import { Mobile } from "./Mobile/Mobile";

export class DeviceSize extends React.Component{
    render(){
        const screenWidth = window.innerWidth;

        if (screenWidth<450){
            return <Mobile />;
        } else if (screenWidth>500 && screenWidth<1280){
            return <Mobile />;
        } else {
            return <Desktop/>;
        }
    }
}