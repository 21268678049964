import React, { Component } from 'react'
import './navburger.css';

export class NavBurger extends Component {
render() {
    return (
        <div className='nav-burger' id='nav-burger' >
            <div className="wrapper">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
}
