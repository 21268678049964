import React from 'react';
import { SubBar } from './SubBar/SubBar';
import { TopBar } from './TopBar/TopBar';
import './NavBar.css';

export class NavBar extends React.Component{
    render(){
        return(
                <header>
                    <TopBar />
                    <SubBar />
                </header>
            );
    }
}
